import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// https://skovy.dev/getting-started-react-concurrent-mode-typescript/

const rootEl = document.getElementById("root") as HTMLElement;
// ReactDOM.render(<App />, rootEl);
ReactDOM.unstable_createRoot(rootEl).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
