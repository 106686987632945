import React, { useRef, useState } from "react";
import { amazonProductLinks, amazonPromoURLs300x250 } from "./config";

enum LoadedState {
  Pending = "pending",
  Loaded = "loaded",
  Error = "error",
}

const AmazonProduct300x250Generic: React.FC<{
  href: string;
  imgSrc: string;
  description?: string;
}> = ({ href, imgSrc, description }) => {
  const [loadedState, setLoadedState] = useState<LoadedState>(
    LoadedState.Pending
  );
  // if (loadedState === LoadedState.Pending) {
  //   return <PlaceHolder width={300} height={250} />;
  // }
  // if (loadedState === LoadedState.Error) {
  //   return null;
  // }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div className="product_div">
        <div className="product-image">
          <img
            src={imgSrc}
            onLoad={() => {
              console.log("LOADED");
              setLoadedState(LoadedState.Loaded);
            }}
            onError={() => setLoadedState(LoadedState.Error)}
          />
        </div>
        {description && <div className="product-desc">{description}</div>}
        <div className="amazon-button">
          <img
            src="/amazon-button.png"
            onLoad={() => setLoadedState(LoadedState.Loaded)}
            onError={() => setLoadedState(LoadedState.Error)}
          />
        </div>
      </div>
    </a>
  );
};

const AmazonPromo300x250Generic: React.FC<{
  src: string;
}> = ({ src }) => {
  const iframe = useRef();
  const [loadedState, setLoadedState] = useState<LoadedState>(
    LoadedState.Pending
  );
  // if (loadedState === LoadedState.Pending) {
  //   return <PlaceHolder width={300} height={250} />;
  // }
  // if (loadedState === LoadedState.Error) {
  //   return null;
  // }
  return (
    <div className="script_div">
      <iframe
        src={src}
        width="300"
        height="250"
        scrolling="no"
        style={{ border: "none" }}
        title={`amazon-300x250`}
        loading="lazy"
        onLoad={() => setLoadedState(LoadedState.Loaded)}
        onError={() => setLoadedState(LoadedState.Error)}
      />
    </div>
  );
};

// const useSuspenseIframe = (src: string) => {
//   const [loadedState, setLoadedState] = useState(DataLoadState.Pending);

//   // https://css-tricks.com/pre-caching-image-with-react-suspense/

//   const iframeRef = useRef<HTMLIFrameElement>();
//   // const handleLoaded = useCallback(() => {
//   //   iframeRef.current =
//   //   setLoadedState(DataLoadState.Loaded);
//   // }, []);
//   // const handleError = useCallback(() => {
//   //   setLoadedState(DataLoadState.Error);
//   // }, []);

//   // console.log("LOADEDSTATE: ", loadedState);

//   const iframeSrcRef = useRef();

//   useEffect(() => {
//     const iframe = document.createElement("iframe");
//     iframe.width = "300px";
//     iframe.height = "2500px";
//     // iframe.scrolling = "no";
//     iframe.setAttribute("style", "border: none;");
//     iframe.onload = () => {
//       iframeRef.current = iframe;
//     };
//     iframe.onerror = () => {
//       iframeRef.current = iframe;
//     };
//     iframe.src = src;
//   }, []);
//   // console.log("hey 1", iframeSrcRef.current.constructor());

//   if (iframeSrcRef instanceof Promise) {
//     console.log("hey done");
//     throw DataLoadState.Pending;
//   }
//   // console.log("hey 2", iframeSrcRef.current.constructor());
//   return iframeSrcRef.current;
// };

// const SuspenseIframe: React.FC<{ src: string }> = ({ src }) => {
//   console.log("YOU 1");
//   const iframe = useSuspenseIframe(src);
//   console.log("YOU 2", iframe);
//   return <div className="script_div">{iframe}</div>;
// };

export const AmazonProduct: React.FC<{ mapKey: string }> = ({ mapKey }) => {
  return <AmazonProduct300x250Generic {...amazonProductLinks[mapKey]} />;
};

export const AmazonPromo: React.FC<{ mapKey: string }> = ({ mapKey }) => {
  return <AmazonPromo300x250Generic src={amazonPromoURLs300x250[mapKey]} />;
};
