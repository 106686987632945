/**
 * Banner ads for Amazon promos (Kindle, Prime, Amazon Music, etc.)
 */
export const amazonPromoURLs300x250: Record<string, string> = {
  biz:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=amzn_biz_bounty&banner=0K3WH19RMBH6B504RGG2&f=ifr&lc=pf4&linkID=a00e0bddd778ace896bd12ec96780860&t=oldwardour-20&tracking_id=oldwardour-20",
  freetime:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=freetime&banner=1AM3MVNDNXNJC2Y46DR2&f=ifr&lc=pf4&linkID=69949da58dc0d9105caa66fa42a9a5b5&t=oldwardour-20&tracking_id=oldwardour-20",
  twitchprime:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=twitchprime&banner=025E4WXKK5G4EXYGDD02&f=ifr&lc=pf4&linkID=84baec28b395469da39a6e79c35cb9d4&t=oldwardour-20&tracking_id=oldwardour-20",
  music:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=amzn_music_bounty&banner=01ZTJ18Y6PDZG4QH4NG2&f=ifr&lc=pf4&linkID=aab506a2d1464c5bc73b97c55c7dbcf4&t=oldwardour-20&tracking_id=oldwardour-20",
  ahs:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=ahs_bounty_16&banner=10R8AMT97WETEZK0SJG2&f=ifr&lc=pf4&linkID=b0b4bd12cc11efe3dd559b5e9271c950&t=oldwardour-20&tracking_id=oldwardour-20",
  primemain:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primemain&banner=028WNSXDMC6H5YDNCB82&f=ifr&lc=pf4&linkID=33acae868897dbef26bcc36073d85b9a&t=oldwardour-20&tracking_id=oldwardour-20",
  primegift:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primegift&banner=10BSGCHPCJG0HZN45H82&f=ifr&lc=pf4&linkID=0d13dfdc92456668abac5b9e5cc30768&t=oldwardour-20&tracking_id=oldwardour-20",
  baby:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=babyregistry&banner=1RFMJ6PT300FWBA0BY82&f=ifr&lc=pf4&linkID=75fda01f3ae3531b6c9885c911661f3e&t=oldwardour-20&tracking_id=oldwardour-20",
  primediscounted:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primediscounted&banner=0B2TM48Z6X1RA9B3TZR2&f=ifr&lc=pf4&linkID=d87f839577d1878082018db318150e9e&t=oldwardour-20&tracking_id=oldwardour-20",
  giftcert:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=gift_certificates&banner=127JF9E4530CSFRCY4R2&f=ifr&linkID=1c8d159195039f2918ec4b4e09439a70&t=oldwardour-20&tracking_id=oldwardour-20",
  audible:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=audiblefreetrial&banner=0YMF8P30ZEBG4A1N9J82&f=ifr&linkID=7d18df0f0b5ebe3807d5475ef885069f&t=oldwardour-20&tracking_id=oldwardour-20",
};

export const amazonPromoURLs300x600 = {
  homepage:
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=49&l=ur1&category=amazonhomepage&f=ifr&linkID=4b0a237d9928b348627ac19810374e0b&t=oldwardour-20&tracking_id=oldwardour-20",
};

/**
 * Specific product recommendations
 */
export const amazonProductLinks: Record<
  string,
  { description: string; href: string; imgSrc: string }
> = {
  bookApocalypse: {
    description:
      "Books: How to Rebuild Civilization in the Aftermath of a Cataclysm",
    href: "https://amzn.to/36ub6aA",
    imgSrc:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0143127047&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=oldwardour-20&language=en_US",
  },
  petWaterBottle: {
    description: 'Dog Water Bottle With Filter',
    href: 'https://amzn.to/2IPCo18',
    imgSrc: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07T35HCQW&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=oldwardour-20&language=en_US"
  },
  switch: {
    description: "Video Games: Nintendo Switch",
    href: "https://amzn.to/36slrDD",
    imgSrc:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07RGFF98S&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=oldwardour-20&language=en_US",
  },
  samsungTV: {
    description: `Electronics: Samsung 55" FRAME 4K UHD TV`,
    href: "https://amzn.to/3l6TrcY",
    imgSrc:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084RMZJDY&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=oldwardour-20&language=en_US",
  },
  succulents: {
    description: `Home: Succulents (5 Pack)`,
    href: "https://amzn.to/3ikThwM",
    imgSrc:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B079RKPQSP&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=oldwardour-20&language=en_US",
  },
  iPhone78Case: {
    description: "iPhone 7/8/SE 2020 Case",
    href: "https://amzn.to/33Uzlg5",
    imgSrc: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KBXDJN7&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=oldwardour-20&language=en_US"
  },
  vehicleAntiTheft: {
    description: 'Vehicle Anti-Theft Device',
    href: 'https://amzn.to/2GWqFgz',
    imgSrc: '//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B06WWHTN4D&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=oldwardour-20&language=en_US'
  }
};
