import React, { Suspense } from "react";

import { AmazonProduct, AmazonPromo } from "./amazon/";

// Need to be in top 500k sites before propeller will allow banner ads
// https://www.alexa.com/siteinfo
// import { PropellerLink1, PropellerPopunder1 } from "./propeller";
import "./App.scss";

const App: React.FC = () => {
  return (
    <div className="App">
      <AmazonPromo mapKey="biz" />
      <AmazonProduct mapKey="petWaterBottle" />
      <AmazonProduct mapKey="succulents" />
      <AmazonPromo mapKey="primemain" />
      <AmazonPromo mapKey="primegift" />
      <Title />
      <AmazonPromo mapKey="baby" />
      <AmazonProduct mapKey="iPhone78Case" />
      <AmazonPromo mapKey="freetime" />
      <AmazonProduct mapKey="samsungTV" />
      <AmazonPromo mapKey="twitchprime" />
      <AmazonProduct mapKey="vehicleAntiTheft" />
      <AmazonPromo mapKey="music" />
      <AmazonProduct mapKey="switch" />
      <AmazonPromo mapKey="ahs" />
      <AmazonPromo mapKey="primediscounted" />
      <AmazonProduct mapKey="bookApocalypse" />
      <AmazonPromo mapKey="giftcert" />
      <AmazonPromo mapKey="audible" />
      <AmazonPromo mapKey="biz" />
      <AmazonProduct mapKey="succulents" />
      <AmazonPromo mapKey="primemain" />
      <AmazonPromo mapKey="primegift" />
      <AmazonPromo mapKey="baby" />
      <AmazonProduct mapKey="samsungTV" />
      <AmazonPromo mapKey="freetime" />
      <AmazonPromo mapKey="twitchprime" />
      <AmazonPromo mapKey="music" />
      <AmazonProduct mapKey="switch" />
      <AmazonPromo mapKey="ahs" />
      <AmazonPromo mapKey="primediscounted" />
      <AmazonProduct mapKey="bookApocalypse" />
      <AmazonPromo mapKey="giftcert" />
      <AmazonPromo mapKey="audible" />
      <AmazonPromo mapKey="biz" />
      <AmazonProduct mapKey="succulents" />
      <AmazonPromo mapKey="primemain" />
      <AmazonPromo mapKey="primegift" />
      <AmazonPromo mapKey="baby" />
      <AmazonProduct mapKey="samsungTV" />
      <AmazonPromo mapKey="freetime" />
      <AmazonPromo mapKey="twitchprime" />
      <AmazonPromo mapKey="music" />
      <AmazonProduct mapKey="switch" />
      <AmazonPromo mapKey="ahs" />
      <AmazonPromo mapKey="primediscounted" />
      <AmazonProduct mapKey="bookApocalypse" />
      <AmazonPromo mapKey="giftcert" />
      <AmazonPromo mapKey="audible" />
    </div>
  );
};

const Title: React.FC = () => (
  <div id="description">
    <h1>HAVE SOME ADS</h1>
  </div>
);


export default App;
